import { Route, Routes } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import "./App.css";
import HomePage from "./pages/HomePage";
import ElegantPalm from "./pages/ElegantPalm";
import ContactUs from "./pages/ContactUs";
import BookPopup from "./components/bookPopup";
import { PopupProvider } from "./context/popupContext";

function App() {
  return (
    <HelmetProvider>
      <div>
        <PopupProvider>
          <Routes>
            <Route path="/" element={<HomePage/>}></Route>
            <Route path="/elegant-palm" element={<ElegantPalm/>}></Route>
            <Route path="/contact-us" element={<ContactUs/>}></Route>
          </Routes>
        </PopupProvider>
      </div>
    </HelmetProvider>
  );
}

export default App;
