import { Helmet } from 'react-helmet-async';
import HeroPalms from '../components/palms/HeroPalms'
import AboutPalms from '../components/palms/AboutPalms'
import Convenience from '../components/palms/Convenience'
import Location from '../components/palms/Location'
import Gallery from '../components/palms/Gallery'
import OtherProjects from '../components/palms/OtherProjects'
import Newsletter from "../components/common/Newsletter";
import Footer from "../components/common/Footer";
import BookPopup from '../components/bookPopup'
import Popup from '../components/Popup'

const ElegantPalm = () => {
  return (
    <>
      <Helmet>
        <title>Elegant Palm | Luxury Apartments in Bangalore by Visionary Venture</title>
        <meta name="description" content="Elegant Palm - Premium 2 & 3 BHK apartments in Bangalore. Experience luxury living with modern amenities, strategic location, and world-class features by Visionary Venture." />
        <meta name="keywords" content="Elegant Palm Bangalore, luxury apartments, 2BHK apartments, 3BHK apartments, residential projects Bangalore, Visionary Venture projects" />
        
        {/* Open Graph Tags for Social Media */}
        <meta property="og:title" content="Elegant Palm - Luxury Apartments in Bangalore" />
        <meta property="og:description" content="Discover premium 2 & 3 BHK apartments at Elegant Palm. Modern amenities, strategic location, and world-class features for luxury living." />
        <meta property="og:image" content="https://www.visionaryventure.co.in/static/media/gallery-slide-one.1411640e33bcff540527.webp" />
        <meta property="og:url" content="https://www.visionaryventure.co.in/elegant-palm" />

        <meta property="og:type" content="website" />
        
        {/* Additional SEO Meta Tags */}
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Visionary Venture" />
        <link rel="canonical" href="https://www.visionaryventure.co.in/elegant-palm" />

        {/* Location-specific meta tags */}
        <meta name="geo.region" content="IN-KA" />
        <meta name="geo.placename" content="Bangalore" />
      </Helmet>

      {/* Add Schema Markup */}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "ApartmentComplex",
            "name": "Elegant Palm",
            "description": "Premium 2 & 3 BHK luxury apartments in Bangalore with modern amenities and strategic location",
            "url": "https://www.visionaryventure.co.in/elegant-palm",
            "image": "https://www.visionaryventure.co.in/images/elegant-palm-exterior.jpg",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Bangalore",
              "addressRegion": "Karnataka",
              "addressCountry": "IN"
            },
            "amenityFeature": [
              {
                "@type": "LocationFeatureSpecification",
                "name": "Swimming Pool"
              },
              {
                "@type": "LocationFeatureSpecification",
                "name": "Gym"
              },
              {
                "@type": "LocationFeatureSpecification",
                "name": "Club House"
              }
            ],
            "numberOfRooms": "2-3 BHK",
            "provider": {
              "@type": "Organization",
              "name": "Visionary Venture",
              "url": "https://www.visionaryventure.co.in"
            }
          }
        `}
      </script>

      <div>
        <HeroPalms />
        <Popup />
        <BookPopup />
        <AboutPalms />
        <Convenience />
        <Location />
        <Gallery />
        <OtherProjects />
        <Newsletter />
        <Footer />
      </div>
    </>
  )
}

export default ElegantPalm