import React, { useState } from "react";
import Hero from "../components/Hero";
import OngoingProjects from "../components/OngoingProjects";
import Popup from "../components/Popup";
import OurTestmonials from "../components/OurTestmonials";
import CompletedProjects from "../components/CompletedProjects";
import Newsletter from "../components/common/Newsletter";
import Footer from "../components/common/Footer";
import About from "../components/About";
import BackToTop from "../components/common/BackToTop";
import BookPopup from "../components/bookPopup";
import { Helmet } from "react-helmet-async";

const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>
          Visionary Venture | Premium Real Estate Developer in Bangalore
        </title>
        <meta
          name="description"
          content="Visionary Venture - Leading real estate developer in Bangalore offering premium apartments, villas, and plots. Experience luxury living with our ongoing projects like Elegant Palm."
        />
        <meta
          name="keywords"
          content="Visionary Venture, real estate developer, Bangalore property, luxury apartments, premium villas, Elegant Palm, residential projects, property investment Bangalore"
        />

        {/* Open Graph Tags for Social Media */}
        <meta
          property="og:title"
          content="Visionary Venture - Premium Real Estate Developer in Bangalore"
        />
        <meta
          property="og:description"
          content="Discover luxury living with Bangalore's premier real estate developer. Explore our premium residential projects, apartments, and villas."
        />
        <meta
          property="og:image"
          content="https://www.visionaryventure.co.in/static/media/nav-logo.368b858b0f1d14947344da56345f91cd.svg"
        />
        <meta property="og:url" content="https://www.visionaryventure.co.in" />
        <meta property="og:type" content="website" />
        {/* Additional SEO Meta Tags */}
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Visionary Venture" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href="https://www.visionaryventure.co.in" />
      </Helmet>
      <Popup />
      <BookPopup />
      <Hero />
      <OngoingProjects />
      <CompletedProjects />
      <About />
      {/* <OurTestmonials /> */}
      <Newsletter />
      <Footer />
      {/* <BackToTop/> */}
    </>
  );
};

export default HomePage;
