import React, { useState } from "react";
import { Helmet } from 'react-helmet-async';
import NavBar from "../components/NavBar";
import Icons from "../components/common/Icons";
import { MdAlternateEmail, MdLocationOn, MdPhone } from "react-icons/md";
import Newsletter from "../components/common/Newsletter";
import Footer from "../components/common/Footer";

const PLAM_CARD_LIST = [
  {
    icon: "Email",
    text: "info@visionaryventures.in",
  },
  {
    icon: "Phone",
    text: "+91 9886161155",
  },
  {
    icon: "Address",
    text: "Visionary Venture\n#788, 3rd Floor, A Block, Opposite Ganesha Temple, Sahakarnagar, Bangalore -560092",
  },
];

function ContactUs() {
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [number, setNumber] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const name = event.target.name.value;
    const email = event.target.email.value;
    const phone = event.target.phone.value;
    const messageContent = event.target.message.value;

    // Input validation
    if (!name || !email || !messageContent || !phone) {
      setMessage("All fields are required.");
      setMessageType("error");
      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setMessage("Please enter a valid email address.");
      setMessageType("error");
      return;
    }

    const numberPattern = /^[0-9]+$/;
    if (!numberPattern.test(phone)) {
      setMessage("Please enter a valid phone number.");
      setMessageType("error");
      return;
    }

    const response = await fetch(
      "https://hooks.zapier.com/hooks/catch/20873108/2ip52gq/",
      {
        method: "POST",
        body: JSON.stringify({ name, email, phone, message: messageContent }),
      }
    );

    if (response.ok) {
      setMessage("Thank you for contacting us!");
      setMessageType("success");

      event.target.reset();
    } else {
      setMessage("There was an error sending your message. Please try again.");
      setMessageType("error");
    }
  };

  return (
    <>
      <Helmet>
        <title>Contact Visionary Venture | Real Estate Developer in Bangalore</title>
        <meta name="description" content="Get in touch with Visionary Venture - Leading real estate developer in Bangalore. Contact us for inquiries about our premium residential projects, apartments, and villas." />
        <meta name="keywords" content="contact Visionary Venture, real estate contact, property enquiry Bangalore, Visionary Venture address, real estate developer contact" />
        
        {/* Open Graph Tags */}
        <meta property="og:title" content="Contact Visionary Venture - Real Estate Developer in Bangalore" />
        <meta property="og:description" content="Connect with Visionary Venture for premium real estate opportunities in Bangalore. Visit our office or reach out through phone or email." />
        <meta property="og:url" content="https://www.visionaryventure.co.in/contact-us" />
        <meta property="og:type" content="website" />
        
        {/* Additional Meta Tags */}
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Visionary Venture" />
        <link rel="canonical" href="https://www.visionaryventure.co.in/contact-us" />
      </Helmet>

      {/* Add Schema Markup */}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "RealEstateAgent",
            "name": "Visionary Venture",
            "@id": "https://www.visionaryventure.co.in",
            "url": "https://www.visionaryventure.co.in",
            "email": "info@visionaryventures.in",
            "telephone": "+91 9886161155",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "#788, 3rd Floor, A Block, Opposite Ganesha Temple, Sahakarnagar",
              "addressLocality": "Bangalore",
              "postalCode": "560092",
              "addressRegion": "Karnataka",
              "addressCountry": "IN"
            },
            "openingHours": "Mo-Sa 09:00-18:00",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+91 9886161155",
              "contactType": "sales",
              "areaServed": "Bangalore",
              "availableLanguage": ["English", "Kannada", "Hindi"]
            }
          }
        `}
      </script>

      <div>
        <div id="home" className="flex flex-col">
          <NavBar />
          <div className="px-5">
            <div className="bg-hero max-w-[1400px] mx-auto h-full lg:min-h-[480px] pb-14 sm:pb-16 pt-14 lg:py-20 sm:pt-16 xl:py-[107px] xl:max-h-[480px] xl:z-30 rounded-2xl relative xl:ps-0 sm:ps-5 ps-0">
              <div className="container max-w-[1180px] flex flex-col justify-center items-center mx-auto sm:px-5 px-4">
                <p className="text-center  text-light-black font-lato font-bold leading-160 flex items-start sm:items-center text-base md:text-lg tracking-[5.12px] gap-2">
                  <span className="max-custom-xs:size-5 max-custom-xs:-me-2"></span>
                  Get in Touch
                </p>
                <h1 className="xl:text-custom-md lg:!text-custom-5xl md:text-5xl text-[40px] font-gloock text-center !leading-122 text-black max-w-[885px] mt-2.5 sm:mb-5 mb-3">
                  Contact Us
                </h1>
                <p className="font-lato text-lg font-medium !leading-normal text-light-black text-center mb-4 lg:mt-7 sm:mb-10">
                  We'd love to hear from you!
                </p>
                <div className="flex flex-wrap gap-4 lg:gap-6 gap-9 max-sm:gap-5 justify-center items-center">
                  {PLAM_CARD_LIST.map((card, index) => (
                    <div
                      key={index}
                      className="xl:px-7 py-5 xl:py-[26px] bg-white duration-300 hover:border-opacity-100 flex flex-col max-lg:justify-center items-center rounded-lg border-yellow border-opacity-20 shadow-villaCard border-[1px] h-[144px] lg:h-[150px] xl:h-[174px] w-[260px] max-sm:w-[133px] max-sm:h-[130px] max-sm:px-2 sm:w-[200px] xl:w-[267px] gap-2"
                    >
                      <div className="flex flex-col items-center gap-3">
                        {card.icon === "Email" && <MdAlternateEmail size={24} />}
                        {card.icon === "Phone" && <MdPhone size={24} />}
                        {card.icon === "Address" && <MdLocationOn size={24} />}
                        <p className="text-base font-normal text-center mt-1 !leading-120 font-gloock max-w-[182px]">
                          {card.text}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <HeroPalms />
        <AboutPalms />
        <Convenience />
        <Location />
        <Gallery />
        <OtherProjects />
      
     */}
        <div className="container mx-auto max-w-md px-5">
          <h2 className="text-center text-2xl font-bold mb-4">
            Send us a message
          </h2>
          <form
            onSubmit={handleSubmit}
            className="flex flex-col items-center  p-4 rounded-lg bg-gray-100 mt-12"
          >
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              required
              className="mb-4 p-2 border rounded w-full"
            />
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              required
              className="mb-4 p-2 border rounded w-full"
            />
            <input
              type="number"
              name="phone"
              placeholder="Your Phone Number"
              required
              className="mb-4 p-2 border rounded w-full"
            />
            <textarea
              name="message"
              placeholder="Your Message"
              required
              className="mb-4 p-2 border rounded w-full"
              rows="4"
            />
            <button type="submit" className="bg-yellow text-white p-2 rounded">
              Send Message
            </button>
          </form>
          {message && (
            <p
              className={`mt-4 ${
                messageType === "success" ? "text-green-500" : "text-red-500"
              }`}
            >
              {message}
            </p>
          )}
        </div>

        <Newsletter />
        <Footer />
      </div>
    </>
  );
}

export default ContactUs;
